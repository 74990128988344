import React from "react";

import alacrityLogo from "../../assets/alacrity-logo.svg";
import { Link } from "react-router-dom";

import "./index.scss";

const Header = () => (
  <div className="alacrity-header">
    <Link className="alacrity-header__logo" to="/">
      <img src={alacrityLogo} alt="Alacrity logo" />
    </Link>
    <h5 className="alacrity-header__tagline">
      A blog for the enlightened pre-college student
    </h5>
  </div>
);

export default Header;
