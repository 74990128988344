import React from "react";

import "./index.scss";
import ShareSocial from "../ShareSocial";
import { RichText } from "prismic-reactjs";

const Article = ({ document }) => {
  const type = document?.type;

  return (
    <div className="article-view">
      {type === "inspiration" ? (
        <h1 className="article-view__inspiration-heading">Inspiration</h1>
      ) : (
        <>
          <h1 className="article-view__title">
            {document?.data.title[0].text}
          </h1>
          <h4 className="article-view__read-time">
            <i className="fas fa-stopwatch"></i>
            {`${document?.data.read_time} min read`}
          </h4>
        </>
      )}
      <img
        className="article-view__title-image"
        src={document?.data.image.url}
        alt={document?.data.image.alt}
      ></img>
      <RichText render={document?.data.text} />
      <ShareSocial />
    </div>
  );
};

export default Article;
