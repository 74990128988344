import React from "react";

import "./index.scss";

const ShareSocial = () => {
  /* eslint-disable no-restricted-globals */
  const openSocialWindow = (url) => {
    var left = (screen.width - 570) / 2;
    var top = (screen.height - 570) / 2;
    var params =
      "menubar=no,toolbar=no,status=no,width=570,height=570,top=" +
      top +
      ",left=" +
      left;
    window.open(url, "NewWindow", params);
  };

  const pageUrl = encodeURIComponent(document.URL);
  const pageDescription = encodeURIComponent(
    document.querySelector("meta[name='description']").getAttribute("content")
  );

  return (
    <div className="share-social">
      <ul className="share-buttons">
        <li>
          <i
            className="fab fa-facebook-f"
            aria-hidden="true"
            onClick={() =>
              openSocialWindow(
                "https://www.facebook.com/sharer.php?u=" + pageUrl
              )
            }
          ></i>
        </li>
        <li>
          <i
            className="fab fa-twitter"
            aria-hidden="true"
            onClick={() =>
              openSocialWindow(
                "https://twitter.com/intent/tweet?url=" +
                  pageUrl +
                  "&text=" +
                  pageDescription
              )
            }
          ></i>
        </li>
        <li>
          <i
            className="fab fa-linkedin-in"
            aria-hidden="true"
            onClick={() =>
              openSocialWindow(
                "https://www.linkedin.com/shareArticle?mini=true&url=" + pageUrl
              )
            }
          ></i>
        </li>
        <li>
          <a href={`mailto:?subject=${pageDescription}&body=${pageUrl}`}>
            <i className="fas fa-envelope" aria-hidden="true"></i>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ShareSocial;
