import React from "react";
import { Link } from "react-router-dom";

import Heading from "../Heading";
import ShareSocial from "../ShareSocial";

import "./index.scss";

const Nav = ({ articles }) => {
  const date = new Date();
  const fullYear = date.getFullYear();
  const resetScroll = () =>
    document.querySelector(".alacrity-content").scroll(0, 0);

  return (
    <div className="alacrity-nav">
      <div className="alacrity-nav__inner-container">
        <div className="alacrity-nav__inner-container--main-nav">
          <Heading>Content</Heading>
          <ul>
            {articles?.map((article, index) => (
              <li key={index}>
                <Link to={`/${article.uid}`} onClick={resetScroll}>
                  {article.data.title[0].text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="alacrity-nav__nav-footer">
        <div className="inspiration-link">
          <Link to="/inspiration">Inspiration</Link>
        </div>
        <ShareSocial />
        <p className="copyright">{`© Alacrity ${fullYear}`}</p>
      </div>
    </div>
  );
};

export default Nav;
