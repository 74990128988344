import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";

import Prismic from "prismic-javascript";
import { client } from "../../prismic-configuration";

import Home from "../Home";
import Article from "../Article";

import "./index.scss";

const Content = ({ articles }) => {
  const [inspiration, setInspiration] = useState(null);

  useEffect(() => {
    const fetchInspiration = async () => {
      const response = await client.query(
        Prismic.Predicates.at("document.type", "inspiration")
      );
      if (response) {
        setInspiration(response.results[0]);
      }
    };
    fetchInspiration();
  });

  const firstFeatured = articles?.find(
    (article) => article.data.special === "featured-1"
  );
  const secondFeatured = articles?.find(
    (article) => article.data.special === "featured-2"
  );
  const thirdFeatured = articles?.find(
    (article) => article.data.special === "featured-3"
  );
  const fourthFeatured = articles?.find(
    (article) => article.data.special === "featured-4"
  );

  return (
    <div className="alacrity-content">
      <div className="alacrity-content__inner">
        <Switch>
          <Route exact path="/">
            <Home
              inspiration={inspiration}
              firstFeatured={firstFeatured}
              secondFeatured={secondFeatured}
              thirdFeatured={thirdFeatured}
              fourthFeatured={fourthFeatured}
            />
          </Route>
          {articles?.map((article, index) => (
            <Route key={index} path={`/${article.uid}`}>
              <Article document={article} />
            </Route>
          ))}
          <Route path="/inspiration">
            <Article document={inspiration} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Content;
