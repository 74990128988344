import React from "react";

import "./index.scss";

const Heading = ({ children }) => {
  return (
    <>
      <h2 className="alacrity-heading">{children}</h2>
      <hr className="alacrity-heading__accent-line"></hr>
    </>
  );
};

export default Heading;
