import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import Prismic from "prismic-javascript";
import { client } from "./prismic-configuration";

import Header from "./components/Header";
import Nav from "./components/Nav";
import Content from "./components/Content";
import "./App.scss";
import GA from "./GoogleAnalytics";

function App() {
  const [articles, setArticles] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      const articlesResponse = await client.query(
        Prismic.Predicates.at("document.type", "post")
      );
      if (articlesResponse) {
        setArticles(articlesResponse.results);
      }
    };
    fetchArticles();
  });

  return (
    <BrowserRouter>
      {GA.init() && <GA.RouteTracker />}
      <div className="App">
        <Header />
        <div className="flex-container">
          <Nav articles={articles} />
          <Content articles={articles} />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
