import React from "react";

import "./index.scss";
import Heading from "../Heading";
import { Link } from "react-router-dom";

const MasonPost = ({ article }) => {
  const resetScroll = () =>
    document.querySelector(".alacrity-content").scroll(0, 0);

  return (
    <div className="post-preview">
      <div className="post-preview__image">
        <Link to={`/${article?.uid}`} onClick={resetScroll}>
          <img
            src={article?.data.image.url}
            alt={article?.data.image.alt}
          ></img>
        </Link>
      </div>
      <div className="post-preview__copy">
        <Link to={`/${article?.uid}`} onClick={resetScroll}>
          <h1 className="post-preview__copy--title">
            {article?.type === "inspiration"
              ? "Inspiration"
              : article?.data.title[0].text}
          </h1>
        </Link>
        <h3 className="post-preview__copy--description">
          {article?.data.short_description}
        </h3>
        {article?.type === "inspiration" ? null : (
          <h4 className="post-preview__copy--read-time">
            <i className="fas fa-stopwatch"></i>
            {`${article?.data.read_time} min read`}
          </h4>
        )}
        <Link
          className="post-preview__copy--read-more"
          to={`/${article?.uid}`}
          onClick={resetScroll}
        >
          Read More
        </Link>
      </div>
    </div>
  );
};

const Home = ({
  inspiration,
  firstFeatured,
  secondFeatured,
  thirdFeatured,
  fourthFeatured,
}) => {
  return (
    <div className="home-view">
      <MasonPost article={inspiration} />
      <Heading>Featured Posts</Heading>
      <div className="featured-wall">
        <MasonPost article={firstFeatured} />
        <MasonPost article={secondFeatured} />
        <MasonPost article={thirdFeatured} />
        <MasonPost article={fourthFeatured} />
      </div>
    </div>
  );
};

export default Home;
