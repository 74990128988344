import Prismic from "prismic-javascript";

// -- Prismic API endpoint
// Determines which repository to query and fetch data from
export const apiEndpoint = "https://alacrity-cms.cdn.prismic.io/api/v2";

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
const accessToken =
  "MC5YMENRY3hJQUFDRUFMUDVW.En7vv73vv73vv70H77-977-977-977-977-9eyHvv71J77-9Ou-_vVZ-77-977-977-9CRwc77-9We-_ve-_vSAC";

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc) => {
  if (doc.type === "page") return `/page/${doc.uid}`;
  return "/";
};

// Client method to query documents from the Prismic repo
export const client = Prismic.client(apiEndpoint, { accessToken });
